export default {
    lg: [
      {
        w: 7,
        h: 14,
        x: 0,
        y: 0,
        minW: 5,
        minH: 14,
        i: "AssessmentStatistics",
      },
      {
        w: 5,
        h: 24,
        x: 7,
        y: 0,
        minW: 4,
        minH: 20,
        i: "UpcomingAssignment",
      },
      {
        w: 4,
        h: 12,
        x: 0,
        y: 14,
        minW: 4,
        minH: 12,
        i: "ClientStatistics",
      },
      {
        w: 3,
        h: 5,
        x: 4,
        y: 14,
        minW: 3,
        minH: 5,
        i: "Notification",
      },
    ],
    md: [
      {
        w: 6,
        h: 13,
        x: 0,
        y: 0,
        minW: 5,
        minH: 13,
        i: "AssessmentStatistics",
      },
      {
        w: 4,
        h: 25,
        x: 6,
        y: 0,
        minW: 3,
        minH: 21,
        i: "UpcomingAssignment",
      },
      {
        w: 3,
        h: 12,
        x: 0,
        y: 13,
        minW: 3,
        minH: 12,
        i: "ClientStatistics",
      },
      {
        w: 3,
        h: 5,
        x: 3,
        y: 13,
        minW: 3,
        minH: 5,
        i: "Notification",
      },
    ],
    sm: [
      {
        w: 6,
        h: 13,
        x: 0,
        y: 0,
        minW: 4,
        minH: 13,
        i: "AssessmentStatistics",
      },
      {
        w: 3,
        h: 21,
        x: 0,
        y: 13,
        minW: 3,
        minH: 21,
        i: "UpcomingAssignment",
      },
      {
        w: 3,
        h: 12,
        x: 3,
        y: 13,
        minW: 3,
        minH: 12,
        i: "ClientStatistics",
      },
      {
        w: 3,
        h: 5,
        x: 3,
        y: 25,
        minW: 3,
        minH: 5,
        i: "Notification",
      },
    ],
    xs: [
      {
        w: 4,
        h: 14,
        x: 0,
        y: 0,
        minW: 3,
        minH: 14,
        i: "AssessmentStatistics",
      },
      {
        w: 4,
        h: 24,
        x: 0,
        y: 14,
        minW: 3,
        minH: 20,
        i: "UpcomingAssignment",
      },
      {
        w: 4,
        h: 12,
        x: 0,
        y: 38,
        minW: 3,
        minH: 12,
        i: "ClientStatistics",
      },
      {
        w: 4,
        h: 6,
        x: 0,
        y: 50,
        minW: 3,
        minH: 6,
        i: "Notification",
      },
    ],
    xxs: [
      {
        w: 2,
        h: 14,
        x: 0,
        y: 0,
        minW: 2,
        minH: 14,
        i: "AssessmentStatistics",
      },
      {
        w: 2,
        h: 20,
        x: 0,
        y: 8,
        minW: 2,
        minH: 20,
        i: "UpcomingAssignment",
      },
      {
        w: 2,
        h: 12,
        x: 0,
        y: 21,
        minW: 2,
        minH: 12,
        i: "ClientStatistics",
      },
      {
        w: 2,
        h: 5,
        x: 0,
        y: 30,
        minW: 2,
        minH: 5,
        i: "Notification",
      },
    ],
  };
  