export const API_ROOT = process.env.REACT_APP_BASE_URL;
// May 17 5:00 PM staging <- master
export const SIGNUP_API = "api/registeruser";
export const VERIFY_EMAIL_API = "api/verify-email";
export const RESEND_EMAIL_API = "api/resend-email";
export const CREATE_PROFILE_API = "api/createProfile";
export const SIGNIN_API = "api/login-user";
export const SIGN_OUT_API = "api/logout";
export const GET_USER_PERMISSION_API = "api/get-user-permissions";
// export const SIGNIN_API = "api/loginuser";
export const BASIC_USER_DETAIL_API = "api/basic-user-detail";
export const FORGOT_PASSWORD_API = "api/forget-password";
export const FORGET_RESET_PASSWORD_API = "api/reset-password";
export const RESET_PASSWORD_API = "api/password-reset";
export const GET_USER_PROFILE_API = "api/user/getUserProfile";
export const UPDATE_PROFILE_API = "api/user/updateProfile";
export const CHANGE_PASSWORD_API = "api/user/changePassword";
export const UPDATE_USER_SOCIAL_PROFILE_API = "api/update-user-social-profile";
export const GET_STATES_API = "api/getStates";
export const GET_CITIES_API = "api/getcities";
export const GET_ORGANIZATION_API = "api/organisationDetails";
export const GET_DEMO_API = "api/registerdemouser";
export const GET_DEMO_USER_LIST_API = "api/getdemouser";
export const GET_INDIVIDUAL_DEMO_USER_API = "api/getdemouserById";
export const UPDATE_INDIVIDUAL_DEMO_USER_API = "api/updateUserById";
export const SEND_OTP_API = "api/sendOTP";
export const VERIFY_OTP_API = "api/verifyOTP";
export const GET_DEVICE_DETAIL_API = "api/user/getDeviceDetail";
export const GET_ASSESSOR_PASSWORD= "api/get-password";

