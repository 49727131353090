import { createSlice } from "@reduxjs/toolkit";

const studentSlice = createSlice({
  name: "student",
  initialState: {
    navPath: null,
    startTime: "",
    questionAll: [],
    question: {},
    questionStats: {},
    resumeTimer: "2:0:0",
    newResumeTimer: 10000,
    humanFaceDetection: [],
    studentBrowserInfo: {},
    lastQuestionId: "",
    updatedQuestionStats: {},
  },
  reducers: {
    navigatePath: (state, { payload }) => {
      state.navPath = payload;
    },
    getStartTime: (state, { payload }) => {
      state.startTime = payload;
    },
    getAssessment: (state, { payload }) => {
      state.questionAll = payload;
    },
    getQuestion: (state, { payload }) => {
      state.question = payload;
    },
    getQuestionStats: (state, { payload }) => {
      state.questionStats = payload;
    },
    updateResumeTimer: (state, { payload }) => {
      state.resumeTimer = payload;
    },
    updateResumeNewTimer: (state, { payload }) => {
      state.newResumeTimer = payload;
    },
    detectHumanFace: (state, { payload }) => {
      state.humanFaceDetection = payload;
    },
    getStudentBrowserInfo: (state, { payload }) => {
      state.studentBrowserInfo = payload;
    },
    getLastQuestionId: (state, { payload }) => {
      state.lastQuestionId = payload;
    },
    getUpdatedStats: (state, { payload }) => {
      state.updatedQuestionStats = payload;
    },
  },
});

export const {
  navigatePath,
  getAssessment,
  getQuestion,
  getQuestionStats,
  getStartTime,
  updateResumeTimer,
  detectHumanFace,
  getStudentBrowserInfo,
  getLastQuestionId,
  getUpdatedStats,
  updateResumeNewTimer,
} = studentSlice.actions;

export const studentSelector = (state) => state.student;
const studentReducer = studentSlice.reducer;
export default studentReducer;
