export const WEBSITE_RELOGIN = "/re-login"
export const SUPER_ADMIN = "/super-admin"
export const SUB_ADMIN = "/sub-admin";


//Auth Routes
export const WEBSITE = "/";
export const SIGNIN = "/signin";
export const SIGNUP = "/signup";
export const GET_DEMO = "/get-demo";
export const EDIT_DEMO_USER = "/edit-user";
export const EDIT_PROFILE = "/edit-profile";
export const FORGOT_PASSWORD = "/forgot-password";
export const GET_OTP = "/get-otp";
export const GET_RESPONSE = "/get-response";
export const RESET_PASSWORD = "/reset-password";
export const FORGET_RESET_PASSWORD = "/reset-password/:resetToken"
export const VERIFY_EMAIL = "/verify-email";
