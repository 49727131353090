import React, { useRef, useState } from "react";
import "./style.css";
import "../../../../../styles/main.css";
// import Logo from "../../../../../assets/images/common/logo.png";
import Logo from "../../../../../assets/images/common/Main_Logo.svg";
import { ReactComponent as BellIcon } from "../../../../../assets/new-icons/bell.svg";
import { ReactComponent as UserIcon } from "../../../../../assets/icons/userIcon.svg";
import { ReactComponent as Setting } from "../../../../../assets/new-icons/settings.svg";
import { ReactComponent as LogOut } from "../../../../../assets/new-icons/power.svg";
import { ReactComponent as HelpIcon } from "../../../../../assets/new-icons/headphones.svg";
import { ReactComponent as DownArrow } from "../../../../../assets/icons/down-arrow.svg";
import { ReactComponent as User } from "../../../../../assets/new-icons/user.svg";
import profile from "../../../../../assets/images/common/profile.png";
import dummyUser from "../../../../../assets/images/pages/admin/dummy-notify-user.png";
import { capitalizeFunc, getUserDetails, getUserType } from "../../../../../utils/projectHelper";
import { useNavigate } from "react-router-dom";
import {
  SUPER_ADMIN_DASHBOARD_PAGE,
  SUPER_ADMIN_MY_ACCOUNT_PAGE,
  SUPER_ADMIN_MY_PROFILE_PAGE,
} from "../../../../../config/constants/routePathConstants/superAdmin";
import { logoutApi, logoutFromDeviceApi, signOutApi } from "../../../../../api/authApi";
import { useDispatch, useSelector } from "react-redux";
import { activitySelector, setMenu, setProfileDropdown } from "../../../../../redux/slicers/activitySlice";
import { authSelector } from "../../../../../redux/slicers/authSlice";
import { useEffect } from "react";
import UserTour from "../UserTour/index";
import { Button } from "@mui/material";
import AvatarImg from "../../../../../assets/images/pages/userProfile/dummy-user-profile.png";

const NavBar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { profileDropdown } = useSelector(activitySelector);
  const { themeToggle, setThemeToggle } = useState(false);
  const { userInfo = {} } = useSelector(authSelector);
  const [open, setOpen] = useState(false);
  const { _id = "", deviceId = "" } = getUserDetails();

  const { userType = 1, firstName = "", lastName = "", isTourComplete, ProfileUrl } = userInfo;

  const [profilePic, setProfilePic] = useState("");
  const fullName = firstName + " " + lastName;
  const handleThemeToggle = (e) => {
    console.log("theme", e.target.checked);
    if (e.target.checked) {
      let title = document.getElementsByTagName("h1");
      let divs = document.getElementsByTagName("div");
      let td = document.getElementsByTagName("td");
      title[0].style.color = "#4f547b";
      for (let i = 0; i < divs.length; i++) {
        // title[i].style.color="white";
        td[i].style.backgroundColor = "#23272f";
        td[i].style.color = "#4f547b";
        divs[i].style.backgroundColor = "#23272f";
        divs[i].style.color = "#4f547b";
      }
    } else {
      let divs = document.getElementsByTagName("div");
      let title = document.getElementsByClassName("title");
      //let divs=document.getElementsByTagName("div");
      let td = document.getElementsByTagName("td");
      console.log(divs);
      for (let i = 0; i < divs.length; i++) {
        divs[i].style.backgroundColor = "";
        td[i].style.backgroundColor = "";
        td[i].style.color = "";
        divs[i].style.backgroundColor = "";
        divs[i].style.color = "";
      }
    }
    // setThemeToggle(!themeToggle)
  };

  const getLocation = () => {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          resolve({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
        },
        (error) => reject(error),
        { enableHighAccuracy: true }, // Request high accuracy for better results
      );
    });
  };

  const getIP = async () => {
    const response = await fetch("https://api64.ipify.org?format=json");
    const data = await response.json();
    return data.ip;
  };

  const handleLogout = async () => {
    const [geolocation, userIp] = await Promise.all([getLocation(), getIP()]);

    const formData = {
      userId: _id,
      isAllLogout: false,
      deviceId: deviceId,
      addreiss: userIp,
      latitude: geolocation.latitude,
      longitude: geolocation.longitude,
      isSimpleLogOut: true,
    };

    dispatch(logoutFromDeviceApi(formData));
    dispatch(setProfileDropdown(false));
  };
  const isDropDownOpen = isTourComplete ? (profileDropdown ? "block" : "none") : "block";

  const handleMenu = () => {
    dispatch(setMenu(true));
  };

  const menuState = useSelector(activitySelector);

  const handleDropdown = (pathname) => {
    navigate(pathname);
    dispatch(setProfileDropdown(false));
  };

  useEffect(() => {
    setProfilePic(ProfileUrl);
  }, [ProfileUrl, themeToggle]);

  return (
    <>
      <UserTour userInfo={userInfo} />
      <nav onClick={() => dispatch(setProfileDropdown(false))}>
        <div className="dashboard-nav">
          <div className="logo" onClick={() => navigate(SUPER_ADMIN_DASHBOARD_PAGE)}>
            <img src={Logo} alt="logo" />
          </div>

          <div className="right-nav">
            {/* <NotifyDropdown open={open} setOpen={setOpen} /> */}
            <span style={{ display: "flex", alignItems: "center" }} id="admin-profile">
              {/* <div style={{display:'flex',alignItems:'center',gap:3}}>
            <p>Dark Theme <input type="checkbox" name="themeToggle" checked={themeToggle} onClick={(e)=>{handleThemeToggle(e)
            setThemeToggle(!themeToggle)}}></input></p>
          </div> */}
              <div className="profile-img-wrapper">
                <img
                  src={profilePic}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = AvatarImg;
                  }}
                  alt="profile"
                  onClick={() => navigate(SUPER_ADMIN_MY_PROFILE_PAGE)}
                />
              </div>
              <div className="dropdown" onClick={(e) => e.stopPropagation()} style={{ position: "relative" }}>
                <p onClick={() => dispatch(setProfileDropdown(!profileDropdown))}>
                  {capitalizeFunc(fullName)}
                  <span>
                    <DownArrow />
                  </span>
                </p>
                <div className="dropdown-options" id="drop-down" style={{ display: isDropDownOpen }}>
                  <div className="option">
                    <p className="check-admin">{getUserType(userType)}</p>
                    <p onClick={() => handleDropdown(SUPER_ADMIN_MY_PROFILE_PAGE)}>
                      <span>
                        <UserIcon />
                      </span>
                      My Profile
                    </p>
                    <p onClick={() => handleDropdown(`${SUPER_ADMIN_MY_ACCOUNT_PAGE}/${_id}`)}>
                      <span>
                        <Setting />
                      </span>
                      Settings
                    </p>
                    {/* <p>
                      <span>
                        <HelpIcon />
                      </span>
                      Help & support
                    </p> */}
                    <p onClick={handleLogout}>
                      <span>
                        <LogOut />
                      </span>
                      Log out
                    </p>
                  </div>
                </div>
              </div>
              <div className="menu-box">
                <button className="hamburger-menu" onClick={handleMenu}>
                  ☰
                </button>
              </div>
            </span>
          </div>
        </div>
        <div id="overlay" style={{ display: menuState.isMenuOpen ? "block" : "" }}></div>
      </nav>
    </>
  );
};

export default NavBar;

export const NotifyDropdown = ({ setOpen, open }) => {
  // close more option popup
  const ref = useRef();

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setOpen(false);
      }
    }

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [ref]);

  return (
    <div className="notification-wrapper">
      <BellIcon
        id="bell-icon"
        onClick={(event) => {
          event.stopPropagation();
          setOpen(!open);
        }}
        className="bell-icon"
        ref={ref}
      />
      <div className="notification-dropdown" style={{ display: open ? "block" : "none" }}>
        <div className="title-wrapper">
          <h6 className="title">Notifications</h6>
          <span>10 new</span>
        </div>
        <ul className="notification-lists">
          {[1, 2, 3, 4, 5]?.map((item) => (
            <li key={item}>
              <div className="img">
                <img src={dummyUser} />
              </div>
              <div className="info">
                <h5>Ajay Kumar Joined the Team?</h5>
                <p>Congratulations him</p>
              </div>
            </li>
          ))}
        </ul>
        <div className="see-all">
          <Button variant="outlined">See All Notifications</Button>
        </div>
      </div>
    </div>
  );
};
